export function joinPath() {
    var _a;
    var parts = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        parts[_i] = arguments[_i];
    }
    return ((_a = parts.slice(1).reduce(function (prev, curr) {
        var next = curr.replace(/^\/+/, '');
        return next.length === 0 ? prev : prev.replace(/\/+$/, '') + '/' + next;
    }, parts[0])) !== null && _a !== void 0 ? _a : '');
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { SkeletonBase } from '../../UI/SkeletonBase';
import './Table.scss';
export var Table = function (props) {
    var columns = props.columns, data = props.data, _a = props.isLoading, isLoading = _a === void 0 ? false : _a, _b = props.sortColumns, sortColumns = _b === void 0 ? [] : _b;
    var tableData = useMemo(function () { return (isLoading ? Array(5).fill({}) : data); }, [isLoading, data]);
    var _c = useTable({
        columns: columns,
        data: tableData,
    }, useSortBy), getTableProps = _c.getTableProps, getTableBodyProps = _c.getTableBodyProps, headerGroups = _c.headerGroups, rows = _c.rows, prepareRow = _c.prepareRow;
    return (_jsxs("table", __assign({}, getTableProps(), { className: "Table", children: [_jsx("thead", { children: headerGroups.map(function (headerGroup) { return (_jsx("tr", __assign({}, headerGroup.getHeaderGroupProps(), { children: headerGroup.headers.map(function (column) { return (_jsxs("th", __assign({}, column.getHeaderProps(sortColumns.includes(column.id)
                        ? column.getSortByToggleProps()
                        : {}), { children: [column.render('Header'), _jsx("span", { children: column.isSorted ? (_jsx(FontAwesomeIcon, { className: "Table-Sort-Icon", icon: column.isSortedDesc ? faArrowDown : faArrowUp })) : ('') })] }), column.getHeaderProps().key)); }) }), headerGroup.getHeaderGroupProps().key)); }) }), _jsx("tbody", __assign({}, getTableBodyProps(), { children: rows.map(function (row) {
                    prepareRow(row);
                    return (_jsx("tr", __assign({}, row.getRowProps(), { children: row.cells.map(function (cell) {
                            return (_jsx("td", __assign({}, cell.getCellProps(), { children: !isLoading ? (cell.render('Cell')) : (_jsx(SkeletonBase, { height: 35, marginTop: 2, width: 125, variant: "text" })) }), cell.getCellProps().key));
                        }) }), row.getRowProps().key));
                }) }))] })));
};

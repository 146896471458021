import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useAppSelector } from '../../../../redux/hooks';
import { CategoryItem } from '../CategoryItem';
export var CategoryList = function () {
    var categories = useAppSelector(function (state) { return state.attachments; }).categories;
    if (!categories)
        return _jsx("div", { children: "Loading..." });
    var categoriesItems = categories.map(function (item) { return (_jsx(CategoryItem, { title: item }, item)); });
    return _jsx(_Fragment, { children: categoriesItems });
};

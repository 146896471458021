import { generateName } from './generateName';
export var parseFacetData = function (facetItems) {
    if (facetItems === void 0) { facetItems = {}; }
    if (Object.values(facetItems).length === 0) {
        return null;
    }
    return Object.entries(facetItems).map(function (_a) {
        var name = _a[0], values = _a[1];
        var filters = !values || (values === null || values === void 0 ? void 0 : values.length) === 0
            ? []
            : values.map(function (item) { return ({
                label: generateLabel(item),
                value: {
                    min: item.min,
                    max: item.max,
                    unit: item.unit,
                },
            }); });
        return {
            name: name,
            filters: filters,
            title: generateName(name, true),
        };
    });
};
var generateLabel = function (item) {
    var max = (item.max >= 1000000);
    if (!max)
        return "".concat(item.min, " - ").concat(item.max, " ").concat(item.unit, " <span class=\"Filter-Count\">(").concat(item.count, ")</span>");
    else
        return "".concat(item.min, "+ ").concat(item.unit, " <span class=\"Filter-Count\">(").concat(item.count, ")</span>");
};

import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../redux/hooks';
import { routes } from '../routes';
export var GuestRoute = function (_a) {
    var children = _a.children;
    var user = useAppSelector(function (state) { return state.user; }).user;
    var isSSOEnabled = useAppSelector(function (state) { return state.auth; }).isSSOEnabled;
    if (!isSSOEnabled)
        return _jsx(Navigate, { to: routes.home });
    if (user) {
        return _jsx(Navigate, { to: routes.home });
    }
    else {
        return _jsx(_Fragment, { children: children });
    }
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setFamilyName, setModel } from '../../redux/slices/attachments';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { Breadcrumb } from '../../components/Breadcrumb';
import { CategoriesSkeleton } from '../../UI/CategoriesSkeleton';
import { CategoryList } from './components/CategoryList';
import './Categories.scss';
export var Categories = function (_a) {
    var isLoading = _a.isLoading;
    var _b = useParams(), model = _b.model, modelGroup = _b.modelGroup;
    var dispatch = useAppDispatch();
    var _c = useAppSelector(function (state) { return state; }).attachments, attachmentModel = _c.model, familyName = _c.familyName;
    var breadcrumbs = [
        {
            label: model,
            to: '/',
        },
        {
            label: 'Select a group',
        },
    ];
    useEffect(function () {
        if (!attachmentModel) {
            dispatch(setModel(model));
        }
    }, [attachmentModel]);
    useEffect(function () {
        if (!familyName) {
            dispatch(setFamilyName(modelGroup));
        }
    }, [familyName, dispatch]);
    return (_jsxs(DefaultLayout, { children: [_jsx(Breadcrumb, { breadcrumbs: breadcrumbs, isExpanded: true }), _jsx("div", { className: "Categories-List-Outer", children: _jsx("div", { className: "Categories-List-Inner", children: isLoading ? _jsx(CategoriesSkeleton, {}) : _jsx(CategoryList, {}) }) })] }));
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Panel } from '@cognitranlimited/itis-web';
import { useLogin } from '../../auth/useLogin';
import './Login.scss';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
export var Login = function () {
    var logIn = useLogin().logIn;
    var location = useLocation();
    useEffect(function () {
        if (location.search === '?login=true' ||
            location.pathname === '/login=true') {
            logIn();
        }
    }, [location]);
    return (_jsx("div", { className: "App-content", children: _jsx("div", { className: "Screen Login Screen-noHeader WelcomeScreen-threeColumns ", children: _jsx("div", { className: "Screen-content", children: _jsx("div", { className: "PanelGrid WelcomeScreen-panelGrid", children: _jsxs(Panel, { className: "WelcomePanel", children: [_jsx(Panel.Header, { children: _jsx("h3", { children: "Login" }) }), _jsxs(Panel.Content, { children: [_jsx("p", { children: "Click here to login" }), _jsx(Button, { onClick: logIn, children: "login" })] })] }) }) }) }) }));
};

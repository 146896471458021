import { useEffect } from 'react';
import { useAppDispatch } from '../redux/hooks';
import { setIsSSOEnabled } from '../redux/slices/auth';
import { resetToInitial } from '../redux/globalActions';
export var useIsSSOEnabled = function () {
    var dispatch = useAppDispatch();
    var isEnabled = process.env.IS_SSO_ENABLED === 'true';
    useEffect(function () {
        if (!isEnabled) {
            dispatch(resetToInitial());
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
        }
        dispatch(setIsSSOEnabled(isEnabled));
    }, []);
};

export var groupFacetsBasedOnUnit = function (_a) {
    var minFlowRateFacetsGPM = _a.minFlowRateFacetsGPM, minFlowRateFacetsLMIN = _a.minFlowRateFacetsLMIN, maxFlowRateFacetsGPM = _a.maxFlowRateFacetsGPM, maxFlowRateFacetsLMIN = _a.maxFlowRateFacetsLMIN, pinDiameterFacetsIN = _a.pinDiameterFacetsIN, pinDiameterFacetsMM = _a.pinDiameterFacetsMM, operatingWidthFacetsIN = _a.operatingWidthFacetsIN, operatingWidthFacetsMM = _a.operatingWidthFacetsMM, loadCapacityFacetsLB = _a.loadCapacityFacetsLB, loadCapacityFacetsKG = _a.loadCapacityFacetsKG, weightFacetsLB = _a.weightFacetsLB, weightFacetsKG = _a.weightFacetsKG, volumeCapacityFacetsYD3 = _a.volumeCapacityFacetsYD3, volumeCapacityFacetsM3 = _a.volumeCapacityFacetsM3, minOperatingPressureFacetsPSI = _a.minOperatingPressureFacetsPSI, minOperatingPressureFacetsBAR = _a.minOperatingPressureFacetsBAR, maxOperatingPressureFacetsPSI = _a.maxOperatingPressureFacetsPSI, maxOperatingPressureFacetsBAR = _a.maxOperatingPressureFacetsBAR, torqueFacetsFTLB = _a.torqueFacetsFTLB, torqueFacetsNM = _a.torqueFacetsNM, sizeFacetsIN = _a.sizeFacetsIN, sizeFacetsMM = _a.sizeFacetsMM;
    return ({
        metric: {
            minFlowRateFacetsLMIN: minFlowRateFacetsLMIN,
            maxFlowRateFacetsLMIN: maxFlowRateFacetsLMIN,
            pinDiameterFacetsMM: pinDiameterFacetsMM,
            operatingWidthFacetsMM: operatingWidthFacetsMM,
            loadCapacityFacetsKG: loadCapacityFacetsKG,
            weightFacetsKG: weightFacetsKG,
            volumeCapacityFacetsM3: volumeCapacityFacetsM3,
            minOperatingPressureFacetsBAR: minOperatingPressureFacetsBAR,
            maxOperatingPressureFacetsBAR: maxOperatingPressureFacetsBAR,
            torqueFacetsNM: torqueFacetsNM,
            sizeFacetsMM: sizeFacetsMM,
        },
        imperial: {
            minFlowRateFacetsGPM: minFlowRateFacetsGPM,
            maxFlowRateFacetsGPM: maxFlowRateFacetsGPM,
            pinDiameterFacetsIN: pinDiameterFacetsIN,
            operatingWidthFacetsIN: operatingWidthFacetsIN,
            loadCapacityFacetsLB: loadCapacityFacetsLB,
            weightFacetsLB: weightFacetsLB,
            volumeCapacityFacetsYD3: volumeCapacityFacetsYD3,
            minOperatingPressureFacetsPSI: minOperatingPressureFacetsPSI,
            maxOperatingPressureFacetsPSI: maxOperatingPressureFacetsPSI,
            torqueFacetsFTLB: torqueFacetsFTLB,
            sizeFacetsIN: sizeFacetsIN,
        },
    });
};

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { JCBLogo } from '../JCBLogo';
import { SidebarContent } from './SidebarContent';
import { routes } from '../../routes';
import { SidebarFooter } from './SidebarFooter';
import { SidebarToggle } from './SidebarToggle';
import { useSwipeGesture } from '../../utils/useSwipeGesture';
export var ExpandedSidebar = function (props) {
    var navElement = useRef(null);
    var classes = classNames('Sidebar', 'ExpandedSidebar', {
        ExpandedSidebar_Floating: props.viewState === 'floating',
    });
    useSwipeGesture({
        element: navElement,
        onSwipeLeft: function () {
            if (props.viewState === 'floating') {
                props.onStopFloating();
            }
        },
    });
    return (_jsxs(_Fragment, { children: [props.viewState === 'floating' && (_jsx("div", { className: "ExpandedSidebar_Cover", "aria-hidden": true, onMouseEnter: props.onStopFloating, onClick: props.onStopFloating })), _jsx(CSSTransition, { in: props.viewState !== 'collapsed', classNames: "ExpandedSidebar_Floating", timeout: 200, mountOnEnter: true, children: _jsxs("nav", { ref: navElement, className: classes, children: [_jsx("div", { className: "ExpandedSidebar_Logo_Wrapper", children: _jsx(Link, { className: "ExpandedSidebar_Logo", to: routes.home, children: _jsx(JCBLogo, {}) }) }), _jsx(SidebarContent, {}), _jsx(SidebarFooter, { children: _jsx(SidebarToggle, { expand: props.viewState !== 'visible', variant: "circle", accented: props.viewState !== 'visible', onExpand: props.onExpand, onCollapse: props.onCollapse }) })] }) })] }));
};

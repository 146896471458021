import sanitize, { simpleTransform } from 'sanitize-html';
var allowedTags = [
    'a',
    'abbr',
    'address',
    'article',
    'aside',
    'b',
    'bdi',
    'bdo',
    'big',
    'blockquote',
    'br',
    'caption',
    'cite',
    'code',
    'col',
    'colgroup',
    'data',
    'del',
    'div',
    'dd',
    'details',
    'dfn',
    'dl',
    'dt',
    'em',
    'figcaption',
    'figure',
    'font',
    'footer',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'header',
    'hr',
    'i',
    'img',
    'ins',
    'kbd',
    'li',
    'main',
    'mark',
    'nav',
    'o',
    'ol',
    'p',
    'pre',
    'q',
    'rp',
    'rt',
    'rtc',
    'ruby',
    's',
    'samp',
    'section',
    'small',
    'span',
    'strike',
    'strong',
    'sub',
    'summary',
    'sup',
    'table',
    'tbody',
    'td',
    'tfoot',
    'th',
    'thead',
    'time',
    'tr',
    'tt',
    'u',
    'ul',
    'var',
    'wbr',
];
var allowedStyles = [
    'background',
    'background-attachment',
    'background-color',
    'background-image',
    'background-position',
    'background-repeat',
    'border',
    'border-bottom',
    'border-bottom-color',
    'border-bottom-left-radius',
    'border-bottom-right-radius',
    'border-bottom-style',
    'border-bottom-width',
    'border-collapse',
    'border-color',
    'border-left',
    'border-left-color',
    'border-left-style',
    'border-left-width',
    'border-radius',
    'border-right',
    'border-right-color',
    'border-right-style',
    'border-right-width',
    'border-spacing',
    'border-style',
    'border-top',
    'border-top-color',
    'border-top-left-radius',
    'border-top-right-radius',
    'border-top-style',
    'border-top-width',
    'border-width',
    'box-shadow',
    'caption-side',
    'color',
    'cue',
    'cue-after',
    'cue-before',
    'direction',
    'elevation',
    'empty-cells',
    'font',
    'font-family',
    'font-size',
    'font-stretch',
    'font-style',
    'font-variant',
    'font-weight',
    'height',
    'letter-spacing',
    'line-height',
    'list-style',
    'list-style-image',
    'list-style-position',
    'list-style-type',
    'margin',
    'margin-bottom',
    'margin-left',
    'margin-right',
    'margin-top',
    'max-height',
    'max-width',
    'min-height',
    'min-width',
    'outline',
    'outline-color',
    'outline-style',
    'outline-width',
    'padding',
    'padding-bottom',
    'padding-left',
    'padding-right',
    'padding-top',
    'pause',
    'pause-after',
    'pause-before',
    'pitch',
    'pitch-range',
    'quotes',
    'richness',
    'speak',
    'speak-header',
    'speak-numeral',
    'speak-punctuation',
    'speech-rate',
    'stress',
    'table-layout',
    'text-align',
    'text-decoration',
    'text-indent',
    'text-overflow',
    'text-shadow',
    'text-transform',
    'text-wrap',
    'unicode-bidi',
    'vertical-align',
    'voice-family',
    'volume',
    'white-space',
    'width',
    'word-spacing',
    'word-wrap',
];
function buildAllowedStyles() {
    var styles = {};
    allowedStyles.forEach(function (style) { return (styles[style] = [/^.+$/]); });
    return styles;
}
var options = {
    allowedTags: allowedTags,
    allowedSchemes: ['http', 'https', 'mailto'],
    allowedSchemesByTag: {
        img: ['data'],
    },
    allowedAttributes: {
        '*': ['style'],
        a: ['href', 'rel', 'target', 'data-iwa-route'],
        img: ['src', 'alt', 'height', 'width'],
        table: ['summary', 'align', 'valign'],
        thead: ['align', 'valign'],
        tbody: ['align', 'valign'],
        tfoot: ['align', 'valign'],
        colgroup: ['align', 'valign'],
        col: ['align', 'valign'],
        tr: ['align', 'valign'],
        th: ['align', 'valign'],
        td: ['align', 'valign'],
    },
    allowedStyles: {
        '*': buildAllowedStyles(),
    },
    nonTextTags: ['style', 'script', 'textarea', 'option', 'noscript', 'title'],
    selfClosing: sanitize.defaults.selfClosing.concat(['col', 'wbr']),
    transformTags: {
        // Add rel and target attributes to all links.
        a: simpleTransform('a', {
            rel: 'nofollow',
            target: '_blank',
        }, true),
    },
};
export function sanitiseHtml(html) {
    return sanitize(html, options);
}

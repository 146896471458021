import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import { getAuthRedirectUrl } from '../utils/getAuthRedirectUrl';
import { getItisUrl } from '../utils/getItisUrl';
export var useLogin = function () {
    var state = uuidv4();
    var logIn = function () {
        var redirectUrl = getAuthRedirectUrl('/');
        var params = {
            client_id: process.env.OAUTH_CLIENT_ID,
            state: state,
            duration: 'permanent',
            response_type: 'code',
            locale: 'en-GB',
            redirect_uri: redirectUrl,
        };
        var url = getItisUrl('/service/oauth2/authorize?' + queryString.stringify(params));
        window.location.assign(process.env.IWA_URL + url);
    };
    return {
        logIn: logIn,
    };
};

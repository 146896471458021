import { createSlice } from '@reduxjs/toolkit';
export var initialState = {
    vehicle: null,
};
export var vehicleSlice = createSlice({
    name: 'vehicle',
    initialState: initialState,
    reducers: {
        setVehicle: function (state, action) {
            state.vehicle = action.payload;
        },
    },
});
export var setVehicle = vehicleSlice.actions.setVehicle;
export default vehicleSlice.reducer;

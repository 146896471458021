import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { resetToInitial } from '../../redux/globalActions';
import { routes } from '../../routes';
import { MenuList } from '../MenuList';
import { SidebarContentAuthenticated } from './SidebarContentAuthenticated';
export var SidebarContentUser = function (_a) {
    var onClick = _a.onClick;
    var dispatch = useAppDispatch();
    var user = useAppSelector(function (state) { return state.user; }).user;
    if (user) {
        return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "SidebarSection SidebarUserSection", children: [_jsxs("p", { className: "SidebarUserInfo", children: ["Logged as: ", user === null || user === void 0 ? void 0 : user.firstName, " ", user === null || user === void 0 ? void 0 : user.lastName] }), _jsx("button", { onClick: function () {
                                dispatch(resetToInitial());
                                localStorage.removeItem('accessToken');
                                localStorage.removeItem('refreshToken');
                            }, className: "Sidebar_LogoutButton", children: "log out" })] }), _jsx(SidebarContentAuthenticated, { onClick: onClick })] }));
    }
    return (_jsx("div", { className: "SidebarSection", children: _jsx(MenuList, { onClick: onClick, menuItems: [
                {
                    href: routes.login,
                    label: 'Login',
                },
            ] }) }));
};

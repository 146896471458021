import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './MachineBox.scss';
import { Link } from 'react-router-dom';
export var MachineBox = function (_a) {
    var onClick = _a.onClick, src = _a.src, title = _a.title, to = _a.to;
    var content = (_jsxs(_Fragment, { children: [_jsx("div", { className: "MachineBox-Image-Container", children: _jsx("img", { className: "MachineBox-Image", src: src, alt: title }) }), _jsxs("div", { className: "MachineBox-Info", children: [_jsx("h3", { className: "MachineBox-Title", children: title }), _jsx(FontAwesomeIcon, { icon: faArrowRight, className: "MachineBox-Icon" })] })] }));
    if (to) {
        return (_jsx(Link, { to: to, className: "MachineBox", children: content }));
    }
    if (onClick) {
        return (_jsx("button", { className: "MachineBox", onClick: onClick, children: content }));
    }
    return _jsx("div", { className: "MachineBox", children: content });
};

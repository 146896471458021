import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Panel, Screen } from '@cognitranlimited/itis-web';
import { cookies } from '../../data/cookies';
import { terms } from '../../data/terms';
import { privacyPolicy } from '../../data/privacyPolicy';
import { LegalDocumentView } from './LegalDocumentView';
import './LegalDocumentScreen.scss';
export var LegalDocumentScreen = function (_a) {
    var documentType = _a.documentType;
    var content = function () {
        switch (documentType) {
            case 'cookies':
                return cookies;
            case 'privacy':
                return privacyPolicy;
            case 'terms':
                return terms;
        }
    };
    var titles = useMemo(function () { return ({
        privacy: 'Privacy Policy',
        terms: 'Terms and Conditions',
        cookies: 'Cookie Policy',
    }); }, []);
    var title = titles[documentType];
    return (_jsxs(Screen, { className: "LegalDocumentScreen", children: [_jsx("div", { className: "ContentHeader", children: _jsx("div", { className: "ContentHeader-content", children: _jsx("div", { className: "ContentHeaderTitle", children: _jsx("div", { className: "ContentHeaderTitle-title", children: _jsx("h2", { children: title }) }) }) }) }), _jsx("div", { className: "LegalDocumentScreen-content", children: content && (_jsx(Panel, { children: _jsx(Panel.Content, { children: _jsx(LegalDocumentView, { className: "LegalDocumentScreen-document", children: content() }) }) })) })] }));
};

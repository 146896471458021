import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './Accordion.scss';
import { MenuItem } from '../../components/MenuItem';
export var Accordion = function (_a) {
    var label = _a.label, children = _a.children, to = _a.to;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    return (_jsxs(_Fragment, { children: [_jsx(MenuItem, { href: to, label: label, withBorder: isOpen, children: _jsx("button", { onClick: function () {
                        setIsOpen(!isOpen);
                    }, className: "Accordion-Header-Button", children: _jsx(FontAwesomeIcon, { icon: isOpen ? faChevronUp : faChevronDown, size: "1x" }) }) }), isOpen ? children : undefined] }));
};

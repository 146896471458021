var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSearchParams } from 'react-router-dom';
import { Term } from '../Term';
import { generateName } from '../../../../utils/generateName';
import './AttachmentsHeader.scss';
import { UnitSwitch } from '../UnitSwitch';
export var AttachmentsHeader = function () {
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    var clearTerm = function (name) {
        searchParams.delete(name);
        setSearchParams(searchParams);
    };
    var getFacetsAndRenderTerms = function () {
        var facetNames = Object.keys(__assign({}, Object.fromEntries(searchParams || [])));
        var params = facetNames.map(function (item) {
            var param = searchParams.get(item);
            if ((item === 'sort' || item === 'description') && param) {
                return { name: item, value: param, title: item };
            }
            if (param) {
                var firstItem = JSON.parse(param)[0];
                var max = (firstItem.max >= 1000000 ? "+ " : (" - " + firstItem.max + " "));
                var joined = firstItem.min.toString() + max + firstItem.unit.toString();
                return { title: generateName(item, true), name: item, value: joined };
            }
            return {
                title: '',
                name: '',
                value: '',
            };
        });
        return params.map(function (param) { return (_jsx(Term, { onClick: function () { return clearTerm(param === null || param === void 0 ? void 0 : param.name); }, value: param.value, title: param === null || param === void 0 ? void 0 : param.title }, param === null || param === void 0 ? void 0 : param.name)); });
    };
    var terms = getFacetsAndRenderTerms();
    return (_jsx("div", { className: "Attachments-Header", children: _jsxs("div", { className: "Attachments-Header-Group", children: [_jsx(UnitSwitch, {}), (terms === null || terms === void 0 ? void 0 : terms.length) > 0 ? (_jsxs("div", { className: "Terms-Container", children: [terms, terms && terms.length >= 2 ? (_jsx("button", { onClick: function () { return setSearchParams(undefined); }, className: "Remove-Button", children: "Remove all" })) : undefined] })) : undefined] }) }));
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { attachmentsService } from '../../../../services/attachments.service';
import { setFamilyName, setModels } from '../../../../redux/slices/attachments';
import { routes } from '../../../../routes';
import { SkeletonBase } from '../../../../UI/SkeletonBase';
import { MachineBox } from '../../../../components/MachineBox';
import './ModelLookup.scss';
var imageNames = {
    '*Brackets': 'Miscellaneous.jpg',
    Backhoe: 'Backhoe Loader.jpg',
    'Compact and Mini Excavators': 'Compact and Mini Excavators.jpg',
    Excavator: 'Heavy Excavator.jpg',
    Hydradig: 'Hydradig.jpg',
    'Light Equipment': 'Light Equipment.png',
    Roto: 'Roto.jpg',
    'Rough Terrain Forklift': 'Rough Terrain Forklift.jpg',
    'Skid Steer': 'Skid Steer.jpg',
    TM: 'Telemaster.jpg',
    'Telescopic Handler': 'Telescopic Handler.jpg',
    Teletruk: 'Teletruck.jpg',
    'Wheel Loader': 'Wheel Loader.jpg',
    Miscellaneous: 'Miscellaneous.jpg',
};
export var ModelLookup = function (_a) {
    var isLoading = _a.isLoading;
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var familyNames = useAppSelector(function (state) { return state.lookup; }).familyNames;
    var getFamilyModels = function (familyName) { return __awaiter(void 0, void 0, Promise, function () {
        var data, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, attachmentsService.getFamilyModels(familyName)];
                case 1:
                    data = (_b.sent()).data;
                    dispatch(setModels(data));
                    return [3 /*break*/, 3];
                case 2:
                    _a = _b.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var chooseFamilyName = function (familyName) { return __awaiter(void 0, void 0, Promise, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    sessionStorage.setItem('currentFamilyName', familyName);
                    dispatch(setFamilyName(familyName));
                    return [4 /*yield*/, getFamilyModels(familyName)];
                case 1:
                    _a.sent();
                    navigate(routes.modelSelection(familyName));
                    return [2 /*return*/];
            }
        });
    }); };
    var modelSkeletons = Array.from({ length: 13 }).map(function (_, i) { return (_jsx("div", { className: "Skeleton-Container", children: _jsx(SkeletonBase, { width: 200, height: 190 }) }, i)); });
    var modelItems = familyNames === null || familyNames === void 0 ? void 0 : familyNames.map(function (familyName) { return (_jsx(MachineBox, { onClick: function () {
            chooseFamilyName(familyName);
        }, src: "".concat(process.env.API_URL, "images/categories/").concat(imageNames[familyName]), title: familyName }, familyName)); });
    return (_jsx("div", { className: "Machines", children: isLoading ? modelSkeletons : modelItems }));
};

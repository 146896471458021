import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import logo from '../../assets/jcb-logo.png';
import './JCBLogo.scss';
export var JCBLogo = function (props) {
    var isSmall = props.isSmall;
    var classes = classNames('JCBLogo', {
        'JCBLogo-Small': isSmall,
    });
    return _jsx("img", { className: classes, src: logo, alt: "jcb-logo" });
};

var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export function splitToChunks(array, n) {
    var result = [];
    var copyArr = __spreadArray([], array, true);
    for (var i = n; i > 0; i--) {
        result.push(copyArr.splice(0, Math.ceil(copyArr.length / i)));
    }
    return result;
}

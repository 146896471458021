import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
export var useAnalyticsEventTracker = function (category) {
    var location = useLocation();
    var eventTracker = function (action, label) {
        ReactGA.event({ category: category, action: action });
    };
    var parseUrlsToTitles = function (url) {
        if (url.includes('terms') ||
            url.includes('privacy') ||
            url.includes('cookies')) {
            return 'Terms, privacy or cookies';
        }
        if (url.includes('model-selection'))
            return 'Model Selection';
        if (url.includes('categories') && !url.includes('attachments')) {
            return 'Attachments Listed';
        }
        if (url.includes('categories') && url.includes('attachments')) {
            return 'Attachments Selected';
        }
        return 'Machine Selection';
    };
    var pageEventTracker = function () {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname + location.search,
            title: parseUrlsToTitles(location.pathname),
        });
    };
    return {
        eventTracker: eventTracker,
        pageEventTracker: pageEventTracker,
    };
};

import { jsx as _jsx } from "react/jsx-runtime";
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { createClassName } from '../../utils/className';
import { HeaderButton } from './HeaderButton';
export var MobileHeader = function (_a) {
    var isMenuOpen = _a.isMenuOpen, onMenuClick = _a.onMenuClick;
    return (_jsx("header", { className: className(), children: _jsx(HeaderButton, { icon: isMenuOpen ? faTimes : faBars, title: "test", variant: "mobile", onClick: onMenuClick }) }));
};
var className = createClassName('MobileHeader');

import { useEffect, useState } from 'react';
export function usePopupFocus(_a) {
    var focusElements = _a.focusElements, blurOnClose = _a.blurOnClose, onEnterPress = _a.onEnterPress;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    useEffect(function () {
        function shouldClose(node) {
            var _a;
            for (var _i = 0, focusElements_1 = focusElements; _i < focusElements_1.length; _i++) {
                var element = focusElements_1[_i];
                if ((_a = element.current) === null || _a === void 0 ? void 0 : _a.contains(node)) {
                    return false;
                }
            }
            return true;
        }
        function handleClickAndFocus(event) {
            if (event.target instanceof Node && shouldClose(event.target)) {
                setIsOpen(false);
            }
        }
        function handleKeyDown(event) {
            if (event.key === 'Escape') {
                setIsOpen(false);
            }
            else if (event.key === 'Enter') {
                onEnterPress === null || onEnterPress === void 0 ? void 0 : onEnterPress();
            }
        }
        var haveAddedListeners = false;
        function removeListeners() {
            if (haveAddedListeners) {
                document.removeEventListener('click', handleClickAndFocus, {
                    capture: true,
                });
                document.removeEventListener('focusin', handleClickAndFocus);
                document.removeEventListener('keydown', handleKeyDown);
                haveAddedListeners = false;
            }
        }
        if (isOpen) {
            document.addEventListener('click', handleClickAndFocus, { capture: true });
            document.addEventListener('focusin', handleClickAndFocus);
            document.addEventListener('keydown', handleKeyDown);
            haveAddedListeners = true;
        }
        else {
            removeListeners();
        }
        return removeListeners;
    }, [focusElements, isOpen, onEnterPress]);
    useEffect(function () {
        var _a;
        if (!isOpen) {
            (_a = blurOnClose === null || blurOnClose === void 0 ? void 0 : blurOnClose.current) === null || _a === void 0 ? void 0 : _a.blur();
        }
    }, [isOpen, blurOnClose]);
    return [isOpen, setIsOpen];
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import classNames from 'classnames';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../../../redux/hooks';
import { insertAt } from '../../../../utils/insertAt';
import { useAnalyticsEventTracker } from '../../../../hooks/useAnalyticsEventTracker';
import { Table } from '../../../../components/Table';
import { CountrySwitch } from '../CountrySwitch';
import { TableImage } from '../TableImage';
import './AttachmentsTable.scss';
export var AttachmentsTable = function (_a) {
    var isLoading = _a.isLoading, isFetched = _a.isFetched, setDialogData = _a.setDialogData;
    var eventTracker = useAnalyticsEventTracker('Attachments Table').eventTracker;
    var _b = useAppSelector(function (state) { return state.attachments; }), attachments = _b.attachments, currency = _b.currency;
    var dataMemoized = useMemo(function () { return attachments; }, [attachments]);
    var isPriceExist = dataMemoized === null || dataMemoized === void 0 ? void 0 : dataMemoized.some(function (item) { return item.price; });
    var columns = useMemo(function () { return [
        {
            id: 'id',
            Header: function () { return _jsx("span", {}); },
            columns: [
                {
                    accessor: 'image',
                    Cell: function (_a) {
                        var row = _a.row, value = _a.value;
                        return (_jsx(TableImage, { id: row.id, value: value, partNumber: row.original.partNumber }));
                    },
                },
                {
                    accessor: 'partNumber',
                    Header: 'Part Number',
                },
                {
                    accessor: 'description',
                    Header: 'Description',
                },
            ],
        },
        {
            id: 'fitmentNote',
            Header: '',
            columns: [
                {
                    accessor: 'fitmentNotes',
                    Header: 'Actions',
                    Cell: function (_a) {
                        var value = _a.value, original = _a.row.original;
                        return (_jsx(IconButton, { "aria-label": "fitment-note", onClick: function () {
                                eventTracker('More Info Button clicked');
                                setDialogData(__assign(__assign({ partNumber: original.partNumber, salesCode: original.salesCode, fitmentNotes: value }, (original.pickUp && original.pickUp !== '0'
                                    ? { pickUp: original.pickUp }
                                    : {})), { multiFunctionControlsRequired: original.multifunctionalControlsRequired, metric: {
                                        width: original.widthMillimeters,
                                        size: original.sizeMillimeters,
                                        weight: original.weightKilograms,
                                        minOperatingPressure: original.minOperatingPressureBar,
                                        maxOperatingPressure: original.maxOperatingPressureBar,
                                        minFlowRate: original.minFlowRateLMin,
                                        maxFlowRate: original.maxFlowRateLMin,
                                        torque: original.torqueNm,
                                        pinDiameter: original.pinDiameterMM,
                                        operatingWidth: original.operatingWidthMM,
                                        volumeCapacity: original.volumeCapacityM3,
                                        loadCapacity: original.loadCapacityKilograms,
                                    }, imperial: {
                                        width: original.widthInches,
                                        size: original.sizeInches,
                                        weight: original.weightPounds,
                                        minOperatingPressure: original.minOperatingPressurePSI,
                                        maxOperatingPressure: original.maxOperatingPressurePSI,
                                        minFlowRate: original.minFlowRateGPM,
                                        maxFlowRate: original.maxFlowRateGPM,
                                        torque: original.torqueFTLB,
                                        pinDiameter: original.pinDiameterInches,
                                        operatingWidth: original.operatingWidthInches,
                                        volumeCapacity: original.volumeCapacityYd3,
                                        loadCapacity: original.loadCapacityPounds,
                                    } }));
                            }, children: _jsx(FontAwesomeIcon, { icon: faCircleInfo, size: "xs", color: "black" }) }));
                    },
                },
            ],
        },
    ]; }, [currency]);
    if (isPriceExist && columns.every(function (column) { return column.id !== 'price'; })) {
        insertAt(columns, 1, {
            id: 'price',
            Header: function () { return _jsx(CountrySwitch, {}); },
            columns: [
                {
                    accessor: 'price',
                    Header: 'List-Price',
                    Cell: function (_a) {
                        var _b;
                        var value = _a.value;
                        if (!value)
                            return _jsx("span", {});
                        var price = (_b = value[currency]) !== null && _b !== void 0 ? _b : null;
                        if (price) {
                            return _jsx("span", { children: "".concat(price.currency, " ").concat(price.price) });
                        }
                        return _jsx("span", {});
                    },
                },
                // {
                //   accessor: 'unit',
                //   Header: 'Unit',
                //   Cell: ({ }: Cell<Attachment>) => {
                //     if (
                //       Object.keys(original).length === 0 ||
                //       !original.price
                //     ) {
                //       return <span></span>
                //     }
                //     const price = original.price[currency]
                //     if (price) {
                //       return <span>{price.unit}</span>
                //     }
                //     return <span></span>
                //   },
                // },
            ],
        });
    }
    var classes = classNames('Attachments-Table', {
        'Attachments-Table-Overflow': !isLoading,
    });
    return (_jsx("div", { className: classes, children: isFetched && (dataMemoized === null || dataMemoized === void 0 ? void 0 : dataMemoized.length) === 0 ? (_jsx("div", { className: "Attachments-Table-No-Result", children: _jsx("h3", { children: "No results" }) })) : (_jsx(Table, { sortColumns: ['partNumber', 'description'], isLoading: isLoading, data: dataMemoized, columns: columns })) }));
};

import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../redux/hooks';
import { routes } from '../routes';
export var AuthenticatedRoute = function (_a) {
    var children = _a.children;
    var user = useAppSelector(function (state) { return state.user; }).user;
    var isSSOEnabled = useAppSelector(function (state) { return state.auth; }).isSSOEnabled;
    if (!isSSOEnabled)
        return _jsx(_Fragment, { children: children });
    if (user) {
        return _jsx(_Fragment, { children: children });
    }
    else {
        return _jsx(Navigate, { to: routes.login });
    }
};

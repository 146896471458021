import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useOnMobile } from '../../utils/mediaQueries';
import { ExpandedSidebar } from './ExpandedSidebar';
import { MobileSidebar } from './MobileSidebar';
import { CollapsedSidebar } from './CollapsedSidebar';
import './Sidebar.scss';
export var Sidebar = function () {
    var onMobile = useOnMobile();
    var _a = useState(false), isCollapsed = _a[0], setIsCollapsed = _a[1];
    var _b = useState(false), isFloating = _b[0], setIsFloating = _b[1];
    function onExpand() {
        setIsCollapsed(false);
        setIsFloating(false);
    }
    var showCollapsed = !onMobile && isCollapsed;
    var viewState = isFloating
        ? 'floating'
        : showCollapsed
            ? 'collapsed'
            : 'visible';
    return (_jsxs(_Fragment, { children: [!onMobile && (_jsx(ExpandedSidebar, { viewState: viewState, onExpand: onExpand, onCollapse: function () { return setIsCollapsed(true); }, onStopFloating: function () { return setIsFloating(false); } })), showCollapsed && (_jsx(CollapsedSidebar, { onStartFloating: function () { return setIsFloating(true); }, onExpand: onExpand })), onMobile && _jsx(MobileSidebar, {})] }));
};

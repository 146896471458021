import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import Placeholder from '../../../../assets/img-placeholder.svg';
import { addImageFallback } from '../../../../utils/addImageFallback';
import { useAppDispatch } from '../../../../redux/hooks';
import { setDialogImage } from '../../../../redux/slices/attachments';
import { useAnalyticsEventTracker } from '../../../../hooks/useAnalyticsEventTracker';
import './TableImage.scss';
export var TableImage = function (_a) {
    var id = _a.id, value = _a.value, partNumber = _a.partNumber;
    var eventTracker = useAnalyticsEventTracker('Attachments Table').eventTracker;
    var dispatch = useAppDispatch();
    var _b = useState(false), isPlaceholder = _b[0], setIsPlaceholder = _b[1];
    var classes = classNames('Table-Picture', {
        'Table-Picture-Placeholder': isPlaceholder,
    });
    useEffect(function () {
        if (value) {
            setIsPlaceholder(value === 'Miscellaneous Attachments.jpg');
        }
    }, [value]);
    if (!value)
        return null;
    return (_jsx("img", { id: id, onClick: function () {
            if (isPlaceholder)
                return;
            eventTracker('Attachment Image clicked');
            dispatch(setDialogImage({
                partNumber: partNumber,
                path: value,
            }));
        }, alt: partNumber, className: classes, src: "".concat(process.env.API_URL, "images/parts/").concat(value), onError: function (e) {
            setIsPlaceholder(true);
            addImageFallback(e, Placeholder);
        } }));
};

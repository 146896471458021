var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import { useQuery } from '@tanstack/react-query';
import 'react-toastify/dist/ReactToastify.css';
import { Auth } from './auth/Auth';
import { GuestRoute } from './auth/GuestRoute';
import { AuthenticatedRoute } from './auth/AuthenticatedRoute';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { setAccessToken } from './redux/slices/auth';
import { setCategories, setFamilyName, setModel, } from './redux/slices/attachments';
import { attachmentsService } from './services/attachments.service';
import { useAnalyticsEventTracker } from './hooks/useAnalyticsEventTracker';
import { AuthCheck } from './auth/AuthCheck';
import { Login } from './pages/Login';
import { Categories } from './pages/Categories/Categories';
import { Attachments } from './pages/Attachments/Attachments';
import { PartOverview } from './pages/PartOverview/PartOverview';
import { Home } from './pages/Lookup';
import { LegalDocumentScreen } from './pages/LegalDocumentScreen';
import { ModelSelection } from './pages/ModelSelection';
import { Sidebar } from './components/Sidebar';
import './App.scss';
import { useLogin } from './auth/useLogin';
import { useIsSSOEnabled } from './hooks/useIsSSOEnabled';
var TRACKING_ID = 'G-MNVNQPS0E2';
ReactGA.initialize(TRACKING_ID);
var App = function () {
    var dispatch = useAppDispatch();
    var location = useLocation();
    var logIn = useLogin().logIn;
    useIsSSOEnabled();
    var model = useAppSelector(function (state) { return state.attachments; }).model;
    var pageEventTracker = useAnalyticsEventTracker('Location').pageEventTracker;
    var _a = useQuery({
        enabled: !!model,
        queryKey: [model],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, attachmentsService.getModelCategories(model)];
            });
        }); },
    }), data = _a.data, isLoading = _a.isLoading;
    useEffect(function () {
        if (data) {
            dispatch(setCategories(data.data));
        }
    }, [data]);
    useEffect(function () {
        var model = sessionStorage.getItem('currentModel');
        var familyName = sessionStorage.getItem('currentFamilyName');
        if (model) {
            dispatch(setModel(model));
        }
        if (familyName) {
            dispatch(setFamilyName(familyName));
        }
    }, [dispatch]);
    useEffect(function () {
        pageEventTracker();
    }, [location.pathname]);
    useEffect(function () {
        var accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            dispatch(setAccessToken(accessToken));
        }
    }, []);
    useEffect(function () {
        if (location.search === '?login=true' ||
            location.pathname === '/login=true') {
            logIn();
        }
    }, [location]);
    return (_jsxs(_Fragment, { children: [_jsxs(Helmet, { children: [_jsx("meta", { name: "robots", content: "noindex" }), _jsx("link", { rel: "stylesheet", type: "text/css", href: "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" }), _jsx("link", { rel: "stylesheet", type: "text/css", href: "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" })] }), _jsxs("div", { className: "App", children: [_jsx(Routes, { children: _jsx(Route, { path: "/auth", element: _jsx(Auth, {}) }) }), _jsx(AuthCheck, { children: _jsxs("div", { className: "App-container", children: [_jsx(Sidebar, {}), _jsx(Routes, { children: _jsxs(Route, { path: "/", children: [_jsx(Route, { path: "/login", element: _jsx(GuestRoute, { children: _jsx(Login, {}) }) }), _jsx(Route, { index: true, element: _jsx(AuthenticatedRoute, { children: _jsx(Home, {}) }) }), _jsxs(Route, { path: ":modelGroup", children: [_jsx(Route, { path: "model-selection", element: _jsx(AuthenticatedRoute, { children: _jsx(ModelSelection, {}) }) }), _jsxs(Route, { path: "categories/:model", children: [_jsx(Route, { index: true, element: _jsx(AuthenticatedRoute, { children: _jsx(Categories, { isLoading: isLoading }) }) }), _jsx(Route, { path: "attachments/:category", element: _jsx(AuthenticatedRoute, { children: _jsx(Attachments, {}) }) })] })] }), _jsx(Route, { path: "/part-search/:partNumber", element: _jsx(AuthenticatedRoute, { children: _jsx(PartOverview, {}) }) }), _jsx(Route, { path: "terms", element: _jsx(LegalDocumentScreen, { documentType: "terms" }) }), _jsx(Route, { path: "privacy", element: _jsx(LegalDocumentScreen, { documentType: "privacy" }) }), _jsx(Route, { path: "cookies", element: _jsx(LegalDocumentScreen, { documentType: "cookies" }) })] }) })] }) }), _jsx(ToastContainer, { position: "top-right" })] })] }));
};
export default App;

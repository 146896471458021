export function transformData(input) {
    if (input === void 0) { input = []; }
    var output = {};
    input.forEach(function (item) {
        if ((item === null || item === void 0 ? void 0 : item.description) || (item === null || item === void 0 ? void 0 : item.sort))
            return;
        for (var prop in item) {
            if (item.hasOwnProperty(prop)) {
                if (!output[prop]) {
                    output[prop] = [];
                }
                output[prop] = output[prop].concat(item[prop]);
            }
        }
    });
    return output;
}

import { jsx as _jsx } from "react/jsx-runtime";
import { useAppSelector } from '../../redux/hooks';
import React from 'react';
import { SidebarContentAuthenticated } from './SidebarContentAuthenticated';
import { SidebarContentUser } from './SidebarContentUser';
export var SidebarContent = function (props) {
    var _a = props.setExpandedState, setExpandedState = _a === void 0 ? undefined : _a;
    var isSSOEnabled = useAppSelector(function (state) { return state.auth; }).isSSOEnabled;
    var onClick = function () {
        if (setExpandedState) {
            setExpandedState('collapsed');
        }
    };
    return (_jsx("div", { className: "SidebarContent", children: isSSOEnabled ? (_jsx(SidebarContentUser, { onClick: onClick })) : (_jsx(SidebarContentAuthenticated, { onClick: onClick })) }));
};

import { createElement, forwardRef, useMemo } from 'react';
import { sanitiseHtml } from '../utils/htmlSanitiser';
/**
 * Component to display raw HTML, sanitised by default.
 *
 * @param props - Component props.
 */
export var HtmlView = forwardRef(function (props, ref) {
    var sanitise = props.sanitise, elementType = props.elementType, className = props.className, children = props.children;
    var sanitised = useMemo(function () {
        return sanitise === undefined || sanitise
            ? sanitiseHtml(children)
            : children;
    }, [sanitise, children]);
    return createElement(elementType !== null && elementType !== void 0 ? elementType : 'div', {
        className: className,
        ref: ref,
        dangerouslySetInnerHTML: {
            __html: sanitised,
        },
    });
});
HtmlView.displayName = 'HtmlView';

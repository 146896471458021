var _a;
import { createSlice } from '@reduxjs/toolkit';
export var initialState = {
    accessToken: '',
    isSSOEnabled: true,
};
export var authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setAccessToken: function (state, action) {
            state.accessToken = action.payload;
        },
        setIsSSOEnabled: function (state, action) {
            state.isSSOEnabled = action.payload;
            state.accessToken = '';
        },
    },
});
export var setAccessToken = (_a = authSlice.actions, _a.setAccessToken), setIsSSOEnabled = _a.setIsSSOEnabled;
export default authSlice.reducer;

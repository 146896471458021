var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSearchParams } from 'react-router-dom';
import { Stack, Switch, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { setUnit } from '../../../../redux/slices/attachments';
import { useAnalyticsEventTracker } from '../../../../hooks/useAnalyticsEventTracker';
import './UnitSwitch.scss';
export var UnitSwitch = function () {
    var dispatch = useAppDispatch();
    var eventTracker = useAnalyticsEventTracker('Unit Switch').eventTracker;
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    var unit = useAppSelector(function (state) { return state.attachments; }).unit;
    var isMetric = unit === 'metric';
    var clearFilters = function () {
        var facetNames = Object.keys(__assign({}, Object.fromEntries(searchParams || [])));
        var leftFacets = facetNames.filter(function (name) { return name !== 'description' && name !== 'sort'; });
        leftFacets.forEach(function (facet) {
            searchParams.delete(facet);
            setSearchParams(searchParams);
        });
    };
    return (_jsxs("div", { className: "Unit-Switch", children: [_jsx(Typography, { fontSize: 14, fontWeight: 700, children: "Unit:" }), _jsxs(Stack, { direction: "row", spacing: 0.5, alignItems: "center", children: [_jsx(Typography, { fontSize: 14, children: "Imperial" }), _jsx(Switch, { onChange: function (e) {
                            var value = e.target.checked ? 'metric' : 'imperial';
                            dispatch(setUnit(value));
                            localStorage.setItem('unitSystem', value);
                            eventTracker("Unit switched to ".concat(value));
                            clearFilters();
                        }, checked: isMetric }), _jsx(Typography, { fontSize: 14, children: "Metric" })] })] }));
};

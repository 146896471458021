import { useEffect, useState } from 'react';
export var MOBILE_BREAKPOINT = 680;
export var TABLET_BREAKPOINT = 1024;
export var onMobile = "(max-width: ".concat(MOBILE_BREAKPOINT, "px)");
export var onTablet = "(max-width: ".concat(TABLET_BREAKPOINT, "px)");
export function useMediaQuery(query) {
    function getMatches() {
        return window.matchMedia(query).matches;
    }
    var _a = useState(getMatches()), matches = _a[0], setMatches = _a[1];
    function listener() {
        setMatches(getMatches());
    }
    useEffect(function () {
        window.addEventListener('resize', listener);
        return function () { return window.removeEventListener('resize', listener); };
    });
    return matches;
}
export function useOnMobile() {
    return useMediaQuery(onMobile);
}
export function useOnTablet() {
    return useMediaQuery(onTablet);
}

export function createClassName(componentName, subComponentName) {
    var base = componentName + (subComponentName !== null && subComponentName !== void 0 ? subComponentName : '');
    return function () {
        var suffixes = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            suffixes[_i] = arguments[_i];
        }
        return suffixes.length === 0
            ? base
            : suffixes
                .flatMap(function (suffix) { return (suffix ? ["".concat(base, "-").concat(suffix)] : []); })
                .join(' ');
    };
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import './Breadcrumb.scss';
export var Breadcrumb = function (_a) {
    var breadcrumbs = _a.breadcrumbs, children = _a.children, isExpanded = _a.isExpanded;
    var classes = classNames('Breadcrumbs', {
        isExpanded: isExpanded,
    });
    var breadcrumbItems = breadcrumbs.map(function (item, index) {
        var _a;
        return (_jsxs(Fragment, { children: [index > 0 ? (_jsx(FontAwesomeIcon, { className: "Breadcrumb-Separator", icon: faChevronRight, size: "lg" })) : undefined, item.to ? (_jsx(Link, { to: item.to, className: "Breadcrumb-Text Breadcrumb-Link", children: item.label })) : (_jsx("span", { className: "Breadcrumb-Text", children: (_a = item.label) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase() }))] }, item.label));
    });
    return (_jsxs("div", { className: classes, children: [_jsx("div", { className: "Breadcrumb", children: breadcrumbItems }), children] }));
};

var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, Fragment } from 'react';
import { DialogContent, Stack, Dialog, DialogTitle, Typography, IconButton, } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { setUnit } from '../../../../redux/slices/attachments';
import { generateName } from '../../../../utils/generateName';
import './InfoDialog.scss';
export var InfoDialog = function (_a) {
    var data = _a.data, handleClose = _a.handleClose;
    var dispatch = useAppDispatch();
    var unitSystem = localStorage.getItem('unitSystem');
    var unit = useAppSelector(function (state) { return state.attachments; }).unit;
    useEffect(function () {
        if (unitSystem) {
            dispatch(setUnit(unitSystem));
        }
    }, []);
    if (!data)
        return null;
    var generateInfoData = function () {
        return Object.entries(data[unit]).map(function (_a) {
            var key = _a[0], value = _a[1];
            return {
                value: value === null || value === void 0 ? void 0 : value.value,
                title: generateName(key),
                unit: value === null || value === void 0 ? void 0 : value.unit,
            };
        });
    };
    var infoData = __spreadArray([
        {
            title: 'Fitment Note',
            value: data === null || data === void 0 ? void 0 : data.fitmentNotes,
        },
        {
            title: 'Sales-Code',
            value: data === null || data === void 0 ? void 0 : data.salesCode,
        },
        {
            title: 'Flow Demand',
            value: data === null || data === void 0 ? void 0 : data.flowDemand,
        },
        {
            title: 'Multi-Function Controls',
            value: data.multiFunctionControlsRequired ? 'Required' : 'Not Required',
        },
        {
            title: 'Pick-Up',
            value: data === null || data === void 0 ? void 0 : data.pickUp,
        }
    ], generateInfoData(), true);
    var infoItems = infoData.map(function (item) {
        var _a;
        return (item === null || item === void 0 ? void 0 : item.value) ? (_jsxs(Fragment, { children: [_jsx(Typography, { fontWeight: "bold", fontSize: 18, mt: 1, children: item.title }), _jsxs(Typography, { children: ["\u2022 ", "".concat(item.value, " ").concat((_a = item.unit) !== null && _a !== void 0 ? _a : '')] })] }, item.title)) : undefined;
    });
    return (_jsx(Dialog, { className: "info-dialog", open: !!data, onClose: handleClose, transitionDuration: { exit: 0 }, children: _jsxs("div", { className: "fitment-dialog", "data-testid": "fitment-dialog", children: [_jsxs("div", { className: "header", children: [_jsx(DialogTitle, { fontWeight: "bold", sx: { padding: 0 }, children: "More info" }), _jsx(IconButton, { className: "icon-button", "aria-label": "close", onClick: handleClose, children: _jsx(FontAwesomeIcon, { icon: faXmark, color: "black", size: "xs" }) })] }), _jsx(Stack, { direction: "row", spacing: 1, justifyContent: "space-between", alignItems: "center", className: "fitment-dialog-bar", children: (data === null || data === void 0 ? void 0 : data.partNumber) ? (_jsx(Typography, { variant: "h5", sx: { marginBottom: 1 }, children: "Part: ".concat(data === null || data === void 0 ? void 0 : data.partNumber) })) : undefined }), _jsx(DialogContent, { className: "fitment-dialog-content", children: infoItems })] }) }));
};

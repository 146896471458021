import { createSlice } from '@reduxjs/toolkit';
export var initialState = {
    familyNames: [],
};
export var lookupSlice = createSlice({
    name: 'lookup',
    initialState: initialState,
    reducers: {
        setFamilyNames: function (state, action) {
            state.familyNames = action.payload;
        },
    },
});
export var setFamilyNames = lookupSlice.actions.setFamilyNames;
export default lookupSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
export var initialState = {
    user: null,
};
export var userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setUser: function (state, action) {
            state.user = action.payload;
        },
    },
});
export var setUser = userSlice.actions.setUser;
export default userSlice.reducer;

export var generateName = function (value, deleteUnitWord) {
    if (deleteUnitWord === void 0) { deleteUnitWord = false; }
    /**
     * At first we need to split camelCase string and remove Facets word
     */
    var facetNameArray = value
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .split(' ')
        .filter(function (item) { return item !== 'Facets'; });
    if (deleteUnitWord) {
        /**
         * To delete unit word from facet name
         */
        facetNameArray.pop();
    }
    var facetName = '';
    facetNameArray.forEach(function (word) {
        facetName = facetName + word.charAt(0).toUpperCase() + word.slice(1) + ' ';
    });
    return facetName.trim();
};

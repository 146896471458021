var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { Highlight, useFuzzySearchList } from '@nozbe/microfuzz/react';
import { useNavigate } from 'react-router-dom';
import { attachmentsService } from '../../services/attachments.service';
import './PartSearch.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
var PartSearch = function () {
    var _a = useState(''), queryText = _a[0], setQueryText = _a[1];
    var _b = useState([]), partNumbers = _b[0], setPartNumbers = _b[1];
    var _c = useState(-1), highlightedIndex = _c[0], setHighlightedIndex = _c[1];
    var _d = useState(false), dropdownVisible = _d[0], setDropdownVisible = _d[1];
    var inputRef = useRef(null);
    var navigate = useNavigate();
    var SUGGESTION_LIMIT = 5;
    useEffect(function () {
        attachmentsService
            .getAllParts()
            .then(function (response) {
            setPartNumbers(response.data);
        })
            .catch(function (error) {
            console.error('Error fetching part numbers:', error); //eslint-disable-line
        });
    }, []);
    // fuzz filter the list via current non-submitted search query text
    var filteredPartNumbers = useFuzzySearchList({
        list: partNumbers,
        queryText: queryText,
        getText: function (partNumber) { return [partNumber]; },
        mapResultItem: function (_a) {
            var item = _a.item, highlightRanges = _a.matches[0];
            return ({
                item: item,
                highlightRanges: highlightRanges,
            });
        },
    });
    // escape / (backslash) of url path
    function encodePartNumber(partNumber) {
        var segments = partNumber.split('/');
        var encodedSegments = segments.map(function (segment) {
            return encodeURIComponent(segment);
        });
        return encodedSegments.join('%2F');
    }
    // utility function to update query text and navigate
    var updateQueryAndNavigate = function (newQueryText) {
        setQueryText(newQueryText);
        setDropdownVisible(false);
        navigate("/part-search/".concat(encodePartNumber(newQueryText)));
        setQueryText('');
    };
    var isLastIndex = function (index) {
        return (index === filteredPartNumbers.length || index === SUGGESTION_LIMIT - 1);
    };
    var nextIndex = function (index) {
        return isLastIndex(index) ? 0 : index + 1;
    };
    var prevIndex = function (index) {
        return Math.max(index - 1, -1);
    };
    // generic match on keyboard events & call helper fn based on event param
    var handleKeyDown = function (event) { return __awaiter(void 0, void 0, Promise, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(event.key === 'ArrowDown')) return [3 /*break*/, 1];
                    setHighlightedIndex(function (index) { return nextIndex(index); });
                    return [3 /*break*/, 5];
                case 1:
                    if (!(event.key === 'ArrowUp')) return [3 /*break*/, 2];
                    event.preventDefault();
                    setHighlightedIndex(function (index) { return prevIndex(index); });
                    return [3 /*break*/, 5];
                case 2:
                    if (!(event.key === 'Tab')) return [3 /*break*/, 3];
                    event.preventDefault();
                    setHighlightedIndex(function (index) {
                        return event.shiftKey ? prevIndex(index) : nextIndex(index);
                    });
                    return [3 /*break*/, 5];
                case 3:
                    if (!(event.key === 'Enter')) return [3 /*break*/, 5];
                    return [4 /*yield*/, handleEnter(event)];
                case 4:
                    _a.sent();
                    _a.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleEnter = function (event) { return __awaiter(void 0, void 0, Promise, function () {
        return __generator(this, function (_a) {
            if (event.key === 'Enter') {
                event.preventDefault();
                if (highlightedIndex >= 0 && filteredPartNumbers[highlightedIndex]) {
                    updateQueryAndNavigate(filteredPartNumbers[highlightedIndex].item);
                }
            }
            return [2 /*return*/];
        });
    }); };
    var handleChange = function (e) {
        setQueryText(e.target.value);
        setHighlightedIndex(-1);
        setDropdownVisible(true);
    };
    var handleSuggestionClick = function (partNumber) { return __awaiter(void 0, void 0, Promise, function () {
        return __generator(this, function (_a) {
            updateQueryAndNavigate(partNumber);
            return [2 /*return*/];
        });
    }); };
    var handleMouseEnter = function (index) {
        setHighlightedIndex(index);
    };
    var handleMouseLeave = function () {
        setHighlightedIndex(-1);
    };
    return (_jsxs("div", { className: "PartSearch", children: [_jsxs("div", { className: "PartSearch-input-container", children: [_jsx(FontAwesomeIcon, { icon: faSearch }), _jsx("input", { className: "PartSearch-input", type: "text", value: queryText, onChange: handleChange, onKeyDown: handleKeyDown, ref: inputRef, placeholder: "Part Search.." })] }), dropdownVisible && queryText && (_jsx("ul", { className: "PartSearch-list", children: filteredPartNumbers
                    .slice(0, 5)
                    .map(function (_a, index) {
                    var item = _a.item, highlightRanges = _a.highlightRanges;
                    var searchItemClasses = classNames('PartSearch-item', {
                        'is-focused': highlightedIndex === index,
                    });
                    return (_jsx("li", { className: searchItemClasses, style: {
                            cursor: 'pointer',
                        }, onMouseEnter: function () { return handleMouseEnter(index); }, onMouseLeave: handleMouseLeave, onMouseDown: function () {
                            handleSuggestionClick(item);
                        }, children: _jsx(Highlight, { text: item, ranges: highlightRanges }) }, item));
                }) }))] }));
};
export default PartSearch;

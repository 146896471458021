var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export function insertAt(array, index) {
    var elementsArray = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        elementsArray[_i - 2] = arguments[_i];
    }
    array.splice.apply(array, __spreadArray([index, 0], elementsArray, false));
}

import { jsx as _jsx } from "react/jsx-runtime";
import Skeleton from '@mui/material/Skeleton';
export var SkeletonBase = function (_a) {
    var height = _a.height, _b = _a.marginBottom, marginBottom = _b === void 0 ? 2 : _b, marginTop = _a.marginTop, marginLeft = _a.marginLeft, marginRight = _a.marginRight, _c = _a.width, width = _c === void 0 ? '100%' : _c, _d = _a.variant, variant = _d === void 0 ? 'rectangular' : _d, omitTestId = _a.omitTestId;
    return (_jsx(Skeleton, { width: width, height: height, sx: {
            mb: marginBottom,
            mt: marginTop,
            mr: marginRight,
            ml: marginLeft,
        }, variant: variant, "data-testid": omitTestId ? '' : 'skeleton' }));
};

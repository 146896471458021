import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import classNames from 'classnames';
export var SidebarToggle = function (_a) {
    var _b;
    var expand = _a.expand, variant = _a.variant, accented = _a.accented, shadow = _a.shadow, onExpand = _a.onExpand, onCollapse = _a.onCollapse;
    var title = expand ? 'expand' : 'collapse';
    var classes = classNames('SidebarToggle', (_b = {
            'SidebarToggle-expand': expand,
            'SidebarToggle-collapse': !expand
        },
        _b["SidebarToggle-".concat(variant)] = variant && variant !== 'circle',
        _b['SidebarToggle-accented'] = accented,
        _b));
    return (_jsxs("div", { className: classes, children: [shadow && _jsx("div", { className: "SidebarToggle-shadow" }), _jsx("button", { className: "SidebarToggle-button", title: title, "aria-label": title, onClick: expand ? onExpand : onCollapse, children: _jsx(FontAwesomeIcon, { icon: expand ? faChevronRight : faChevronLeft, size: "1x" }) })] }));
};

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { routes } from '../../routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion } from '../../components/Accordion';
import { AttachmentsList } from '../AttachmentsList';
import PartSearch from '../PartSearch/PartSearch';
import { MenuList } from '../MenuList';
import { useAppSelector } from '../../redux/hooks';
import { useAnalyticsEventTracker } from '../../hooks/useAnalyticsEventTracker';
export var SidebarContentAuthenticated = function (_a) {
    var onClick = _a.onClick;
    var eventTracker = useAnalyticsEventTracker('Contact').eventTracker;
    var _b = useAppSelector(function (state) { return state.attachments; }), model = _b.model, familyName = _b.familyName;
    var vehicle = useAppSelector(function (state) { return state.vehicle; }).vehicle;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "SidebarSection", children: _jsx(MenuList, { onClick: onClick, menuItems: [{ href: routes.home, label: 'Machine Selection' }] }) }), (vehicle !== null && vehicle !== void 0 ? vehicle : model) ? (_jsx("div", { className: "SidebarSection", children: _jsx(MenuList, { onClick: onClick, menuItems: [
                        {
                            href: routes.modelSelection(familyName),
                            label: "Machine: ".concat(model),
                        },
                    ] }) })) : undefined, model ? (_jsx(_Fragment, { children: _jsx("div", { className: "SidebarSection", children: _jsx("div", { children: _jsx("ul", { className: "Menu-List", children: _jsx(Accordion, { to: routes.categories(familyName, model), label: "Categories", children: _jsx(AttachmentsList, { onClick: onClick }) }) }) }) }) })) : undefined, _jsx("div", { className: "SidebarSection", children: _jsx(PartSearch, {}) }), _jsx("div", { className: "SidebarSection SidebarSection-Small", children: _jsx("ul", { className: "Menu-List", children: _jsxs("li", { className: "Menu-Item Menu-Item-With-Icon", children: [_jsx("a", { className: "Menu-Item-Link", href: "mailto:feedback@JCB.com", onClick: function () {
                                    eventTracker('feedback button was clicked');
                                    onClick();
                                }, children: "Feedback" }), _jsx(FontAwesomeIcon, { className: "Menu-Item-Icon", icon: faComment })] }) }) })] }));
};

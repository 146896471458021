import { jsx as _jsx } from "react/jsx-runtime";
import { useAppSelector } from '../../../../redux/hooks';
import { MachineBox } from '../../../../components/MachineBox';
import { routes } from '../../../../routes';
export var CategoryItem = function (props) {
    var title = props.title;
    var _a = useAppSelector(function (state) { return state.attachments; }), model = _a.model, familyName = _a.familyName;
    var url = "".concat(process.env.API_URL, "images/categories/").concat(title.replaceAll('/', '-'), ".jpg");
    return (_jsx(MachineBox, { src: url, title: title, to: routes.categoriesAttachments(familyName, model, title) }));
};

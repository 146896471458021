import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export var HeaderButton = function (_a) {
    var icon = _a.icon, title = _a.title, variant = _a.variant, onClick = _a.onClick, children = _a.children;
    var classes = classNames('HeaderButton', {
        'HeaderButton-mobile': variant === 'mobile',
    });
    var content = (_jsxs(_Fragment, { children: [_jsx(FontAwesomeIcon, { icon: icon, size: variant === 'mobile' ? 'lg' : '2x' }), children] }));
    return (_jsx("button", { className: classes, title: title, onClick: onClick, children: content }));
};

import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { LinearProgress } from '@mui/material';
import { useAuth } from './useAuth';
export var AuthCheck = function (_a) {
    var children = _a.children;
    var _b = useAuth(), connectionStatus = _b.connectionStatus, isUserDataExist = _b.isUserDataExist;
    if (connectionStatus === 'pending') {
        return _jsx(LinearProgress, { className: "Linear-Progress" });
    }
    if (connectionStatus === 'successful' && isUserDataExist) {
        return _jsx(_Fragment, { children: children });
    }
    if (connectionStatus === 'rejected') {
        return _jsx(_Fragment, { children: children });
    }
    return null;
};

import { jsx as _jsx } from "react/jsx-runtime";
import { useAppSelector } from '../../redux/hooks';
import { routes } from '../../routes';
import { MenuList } from '../../components/MenuList';
export var AttachmentsList = function (props) {
    var _a = props.onClick, onClick = _a === void 0 ? undefined : _a;
    var _b = useAppSelector(function (state) { return state.attachments; }), categories = _b.categories, model = _b.model, familyName = _b.familyName;
    if (categories === null || categories === undefined)
        return null;
    var attachments = categories.map(function (item) { return ({
        label: item.toLocaleLowerCase(),
        href: routes.categoriesAttachments(familyName, model, item),
    }); });
    return (_jsx(MenuList, { className: "Attachments-List", onClick: onClick, menuItems: attachments }));
};

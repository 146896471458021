import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import queryString from 'query-string';
import './MenuItem.scss';
export var MenuItem = function (_a) {
    var label = _a.label, href = _a.href, withBorder = _a.withBorder, onClick = _a.onClick, children = _a.children;
    var location = useLocation();
    var isSelected = useMemo(function () {
        var url = queryString.parseUrl(href).url;
        return decodeURIComponent(url) === decodeURIComponent(location.pathname);
    }, [href, location.pathname]);
    var classes = classNames('Menu-Item', {
        'Menu-Item-Border': withBorder,
        'Menu-Item-Selected': isSelected,
        'Menu-Item-Flex': !!children,
    });
    return (_jsxs("li", { className: classes, children: [_jsx(Link, { className: "Menu-Item-Link", to: href, onClick: onClick, children: label }), children] }));
};

var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialFacets = {
    minFlowRateFacetsGPM: [],
    minFlowRateFacetsLMIN: [],
    maxFlowRateFacetsGPM: [],
    maxFlowRateFacetsLMIN: [],
    pinDiameterFacetsIN: [],
    pinDiameterFacetsMM: [],
    operatingWidthFacetsIN: [],
    operatingWidthFacetsMM: [],
    loadCapacityFacetsLB: [],
    loadCapacityFacetsKG: [],
    weightFacetsLB: [],
    weightFacetsKG: [],
    volumeCapacityFacetsYD3: [],
    volumeCapacityFacetsM3: [],
    minOperatingPressureFacetsPSI: [],
    minOperatingPressureFacetsBAR: [],
    maxOperatingPressureFacetsPSI: [],
    maxOperatingPressureFacetsBAR: [],
    torqueFacetsFTLB: [],
    torqueFacetsNM: [],
    sizeFacetsIN: [],
    sizeFacetsMM: [],
};
export var initialState = {
    model: '',
    familyName: '',
    categories: [],
    facets: initialFacets,
    categoryFacets: initialFacets,
    attachments: [],
    currency: 'USD',
    unit: 'imperial',
    dialogImage: {
        partNumber: '',
        path: '',
    },
    models: [],
};
export var attachmentsSlice = createSlice({
    name: 'attachments',
    initialState: initialState,
    reducers: {
        setCategories: function (state, action) {
            state.categories = action.payload;
        },
        setFacets: function (state, action) {
            state.facets = action.payload;
        },
        setFamilyName: function (state, action) {
            state.familyName = action.payload;
        },
        setCategoryFacets: function (state, action) {
            state.categoryFacets = action.payload;
        },
        setAttachments: function (state, action) {
            state.attachments = action.payload;
        },
        setModel: function (state, action) {
            state.model = action.payload;
        },
        setCurrency: function (state, action) {
            state.currency = action.payload;
        },
        setUnit: function (state, action) {
            state.unit = action.payload;
        },
        setDialogImage: function (state, action) {
            state.dialogImage = action.payload;
        },
        setModels: function (state, action) {
            state.models = action.payload;
        },
    },
});
export var setCategories = (_a = attachmentsSlice.actions, _a.setCategories), setFacets = _a.setFacets, setCategoryFacets = _a.setCategoryFacets, setAttachments = _a.setAttachments, setModel = _a.setModel, setCurrency = _a.setCurrency, setUnit = _a.setUnit, setDialogImage = _a.setDialogImage, setModels = _a.setModels, setFamilyName = _a.setFamilyName;
export default attachmentsSlice.reducer;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { routes } from '../../routes';
import { transformData } from '../../utils/transformData';
import { attachmentsService } from '../../services/attachments.service';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setAttachments, setCategoryFacets, setFacets, setFamilyName, setModel, } from '../../redux/slices/attachments';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { Breadcrumb } from '../../components/Breadcrumb';
import { Filters } from './components/Filters';
import { AttachmentsTable } from './components/AttachmentsTable';
import { AttachmentsHeader } from './components/AttachmentsHeader';
import { InfoDialog } from './components/InfoDialog';
import { ImageDialog } from './components/ImageDialog';
import './Attachments.scss';
import { SearchInput } from './components/SearchInput';
export var Attachments = function () {
    var _a = useParams(), category = _a.category, model = _a.model, modelGroup = _a.modelGroup;
    var searchParams = useSearchParams()[0];
    var dispatch = useAppDispatch();
    var attachments = useAppSelector(function (state) { return state.attachments; });
    var _b = useState(), dialogData = _b[0], setDialogData = _b[1];
    var facetNames = Object.keys(__assign({}, Object.fromEntries(searchParams || [])));
    var breadcrumbs = [
        {
            label: model,
            to: '/',
        },
        {
            label: 'Categories',
            to: routes.categories(attachments.familyName, model),
        },
        {
            label: category,
        },
    ];
    var parseParams = function () {
        var param = facetNames === null || facetNames === void 0 ? void 0 : facetNames.map(function (item) {
            var _a;
            if (item === 'description' || item === 'sort')
                return null;
            var params = searchParams.get(item);
            if (!params)
                return null;
            return _a = {},
                _a[item] = JSON.parse(params || ''),
                _a;
        });
        var description = searchParams === null || searchParams === void 0 ? void 0 : searchParams.get('description');
        var sort = searchParams === null || searchParams === void 0 ? void 0 : searchParams.get('sort');
        var body = transformData(param);
        var bodyWithDesc = __assign({ sort: sort ? JSON.parse(sort) : null, description: description }, body);
        return bodyWithDesc;
    };
    var apiParams = parseParams();
    var _c = useQuery({
        enabled: !!model && !!category,
        queryKey: [apiParams, model, category],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, attachmentsService.getAttachmentsForModel(model, category, apiParams)];
            });
        }); },
    }), data = _c.data, isLoading = _c.isLoading, isFetched = _c.isFetched;
    useEffect(function () {
        if (data) {
            dispatch(setAttachments(data.data.attachments));
            dispatch(setFacets(data.data.facets));
            dispatch(setCategoryFacets(data.data.categoryFacets));
        }
    }, [data]);
    useEffect(function () {
        if (!(attachments === null || attachments === void 0 ? void 0 : attachments.model)) {
            dispatch(setModel(model));
        }
    }, [attachments]);
    useEffect(function () {
        if (!attachments.familyName) {
            dispatch(setFamilyName(modelGroup));
        }
    }, [attachments.familyName, dispatch]);
    return (_jsxs(DefaultLayout, { children: [_jsx(Breadcrumb, { breadcrumbs: breadcrumbs }), _jsxs("div", { className: "Attachments", children: [_jsxs("div", { className: "SearchWrapper", children: [_jsx(SearchInput, {}), _jsx("p", { className: "SearchWrapper-ImageInfo", children: "**Image likeness may differ" })] }), _jsx(AttachmentsHeader, {}, category), _jsxs("div", { className: "Attachments-Wrapper", "data-testid": "attachments-wrapper", children: [_jsx("div", { className: "attachments-actions", children: _jsx(Filters, {}, category) }), _jsx(AttachmentsTable, { isLoading: isLoading, isFetched: isFetched, setDialogData: setDialogData }), _jsx(InfoDialog, { data: dialogData, handleClose: function () { return setDialogData(undefined); } })] })] }), _jsx(ImageDialog, {})] }));
};

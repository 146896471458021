import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import './Term.scss';
export var Term = function (props) {
    var onClick = props.onClick, value = props.value, title = props.title;
    var parseTerm = function () {
        if (typeof value === 'string')
            return value;
        return "".concat(value.min, " - ").concat(value.max, " ").concat(value.unit);
    };
    var parsedTerm = parseTerm();
    return (_jsxs("button", { className: "Term", onClick: onClick, "data-testid": "delete-button", children: [_jsxs("p", { className: "Term-Text", children: [_jsxs("span", { className: "Term-Text-Bold", children: [title, ":"] }), " ", parsedTerm] }), _jsx("span", { className: "Term-Button", children: _jsx(FontAwesomeIcon, { icon: faXmark }) })] }));
};

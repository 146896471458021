import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { usePopupFocus } from '../../utils/popupFocus';
import { routes } from '../../routes';
import { JCBLogo } from '../JCBLogo';
import { MobileHeader } from './MobileHeader';
import { SidebarFooter } from './SidebarFooter';
import { SidebarContent } from './SidebarContent';
export var MobileSidebar = function () {
    var _a = useState('collapsed'), expandedState = _a[0], setExpandedState = _a[1];
    var containerRef = useRef(null);
    var _b = usePopupFocus({
        focusElements: [containerRef],
    }), isOpen = _b[0], setIsOpen = _b[1];
    useEffect(function () {
        if (expandedState !== 'collapsed') {
            setIsOpen(true);
        }
    }, [expandedState, setIsOpen]);
    useEffect(function () {
        if (!isOpen) {
            setExpandedState('collapsed');
        }
    }, [isOpen]);
    function toggleExpanded() {
        setExpandedState(expandedState === 'collapsed' ? 'expanded' : 'collapsed');
    }
    return (_jsxs("div", { className: "Sidebar MobileSidebar", ref: containerRef, children: [_jsx(MobileHeader, { isMenuOpen: expandedState !== 'collapsed', onMenuClick: toggleExpanded }), _jsx(CSSTransition, { in: expandedState === 'expanded', classNames: "MobileSidebar-expanded", timeout: 200, mountOnEnter: true, children: _jsxs("nav", { className: "MobileSidebar-expanded", children: [_jsx(SidebarContent, { setExpandedState: setExpandedState }), _jsx(SidebarFooter, { children: _jsx(Link, { to: routes.home, className: "MobileSidebar-logo", children: _jsx(JCBLogo, { isSmall: true }) }) })] }) })] }));
};

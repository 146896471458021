import { useEffect } from 'react';
export var defaultSwipeThreshold = 50;
export function useSwipeGesture(_a) {
    var elementRef = _a.element, swipeThreshold = _a.swipeThreshold, onSwipeLeft = _a.onSwipeLeft, onSwipeRight = _a.onSwipeRight;
    useEffect(function () {
        if (!elementRef.current) {
            return;
        }
        var element = elementRef.current;
        var threshold = swipeThreshold !== null && swipeThreshold !== void 0 ? swipeThreshold : defaultSwipeThreshold;
        var swipeStartX = 0;
        function handleTouchStart(event) {
            swipeStartX = event.changedTouches[0].screenX;
        }
        function handleTouchMove(event) {
            var swipeEndX = event.changedTouches[0].screenX;
            var swipeAmountX = swipeEndX - swipeStartX;
            if (document.dir === 'rtl') {
                swipeAmountX = -swipeAmountX;
            }
            if (swipeAmountX <= -threshold) {
                onSwipeLeft === null || onSwipeLeft === void 0 ? void 0 : onSwipeLeft();
            }
            else if (swipeAmountX >= threshold) {
                onSwipeRight === null || onSwipeRight === void 0 ? void 0 : onSwipeRight();
            }
        }
        element.addEventListener('touchstart', handleTouchStart);
        element.addEventListener('touchmove', handleTouchMove);
        return function () {
            element.removeEventListener('touchstart', handleTouchStart);
            element.removeEventListener('touchmove', handleTouchMove);
        };
    }, [elementRef, swipeThreshold, onSwipeLeft, onSwipeRight]);
}

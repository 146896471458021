import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Stack, Switch, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import USFlag from '../../../../assets/us_flag.png';
import CadFlag from '../../../../assets/cad_flag.png';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { setCurrency } from '../../../../redux/slices/attachments';
import './CountrySwitch.scss';
export var CountrySwitch = function () {
    var dispatch = useAppDispatch();
    var countryCurrency = localStorage.getItem('countryCurrency');
    var currency = useAppSelector(function (state) { return state.attachments; }).currency;
    var switchCountry = function (checked) {
        var value = checked ? 'CAD' : 'USD';
        dispatch(setCurrency(value));
        localStorage.setItem('countryCurrency', value);
    };
    useEffect(function () {
        if (countryCurrency) {
            dispatch(setCurrency(countryCurrency));
        }
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { fontSize: 14, fontWeight: 700, children: "Currency" }), _jsxs(Stack, { direction: "row", spacing: 1, alignItems: "center", children: [_jsxs("div", { children: [_jsx("img", { src: USFlag, className: "switch-flag" }), _jsx(Typography, { fontSize: 12, children: "USD" })] }), _jsx(Switch, { inputProps: {
                            'data-testid': 'currency-switch',
                        }, onChange: function (e) {
                            switchCountry(e.target.checked);
                        }, checked: currency === 'CAD' }), _jsxs("div", { children: [_jsx("img", { src: CadFlag, className: "switch-flag" }), _jsx(Typography, { fontSize: 12, children: "CAD" })] })] })] }));
};

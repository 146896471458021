import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders } from '@fortawesome/free-solid-svg-icons';
import { parseFacetData } from '../../../../utils/parseFacetData';
import { useAppSelector } from '../../../../redux/hooks';
import { groupFacetsBasedOnUnit } from '../../../../utils/groupFacetsBasedOnUnit';
import { FilterGroup } from './components/FilterGroup';
import './Filters.scss';
export var Filters = function () {
    var _a = useAppSelector(function (state) { return state.attachments; }), categoryFacets = _a.categoryFacets, facets = _a.facets, unit = _a.unit;
    var categoryUnitFacets = groupFacetsBasedOnUnit(categoryFacets);
    var categoryFacetData = parseFacetData(categoryUnitFacets[unit]);
    var unitFacets = groupFacetsBasedOnUnit(facets);
    var facetData = parseFacetData(unitFacets[unit]);
    var filterItems = categoryFacetData === null || categoryFacetData === void 0 ? void 0 : categoryFacetData.map(function (item) {
        var facet = facetData === null || facetData === void 0 ? void 0 : facetData.find(function (facetItem) { return facetItem.name === item.name; });
        return (_jsx(FilterGroup, { title: item === null || item === void 0 ? void 0 : item.title, filters: item === null || item === void 0 ? void 0 : item.filters, facetData: facet, name: item === null || item === void 0 ? void 0 : item.name }, item.title));
    });
    return (_jsxs("div", { className: "Filters", children: [_jsxs("div", { className: "Filters-Header", children: [_jsx(FontAwesomeIcon, { icon: faSliders }), _jsx("p", { className: "Filters-Header-Title", children: "Filters:" })] }), _jsx("div", { className: "Filters-Container", children: filterItems })] }));
};

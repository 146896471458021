import { jsx as _jsx } from "react/jsx-runtime";
import { Dialog } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { setDialogImage } from '../../../../redux/slices/attachments';
export var ImageDialog = function () {
    var dispatch = useAppDispatch();
    var dialogImage = useAppSelector(function (state) { return state.attachments; }).dialogImage;
    var onClose = function () {
        dispatch(setDialogImage({
            partNumber: '',
            path: '',
        }));
    };
    return (_jsx(Dialog, { open: !!dialogImage.path, onClose: onClose, children: _jsx("img", { onClick: onClose, src: "".concat(process.env.API_URL, "images/parts/").concat(dialogImage.path), alt: dialogImage.partNumber }) }));
};

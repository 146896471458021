import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { clientOAuth } from '../http-common';
import queryString from 'query-string';
import { useAppDispatch } from '../redux/hooks';
import { setAccessToken } from '../redux/slices/auth';
import { routes } from '../routes';
import { getAuthRedirectUrl } from '../utils/getAuthRedirectUrl';
import { getAuthorisationHeader } from '../utils/getAuthorisationHeader';
export var Auth = function () {
    var search = useLocation().search;
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var params = new URLSearchParams(search);
    var code = params.get('code');
    var error = params.get('error');
    useEffect(function () {
        var client = clientOAuth;
        client
            .post('/jcb/service/oauth2/access_token', queryString.stringify({
            grant_type: 'authorization_code',
            code: code,
            redirect_uri: getAuthRedirectUrl('/'),
        }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: getAuthorisationHeader(process.env.OAUTH_CLIENT_ID, process.env.OAUTH_CLIENT_SECRET),
            },
        })
            .then(function (res) {
            localStorage.setItem('accessToken', res.data.access_token);
            localStorage.setItem('refreshToken', res.data.refresh_token);
            dispatch(setAccessToken(res.data.access_token));
            navigate(routes.home);
        });
    }, []);
    if (error && error === 'access_denied') {
        toast.error('Access denied.');
        return _jsx(Navigate, { to: "/login" });
    }
    return null;
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, InputAdornment } from '@mui/material';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb } from '../../components/Breadcrumb';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { splitToChunks } from '../../utils/splitIntoChunks';
import { attachmentsService } from '../../services/attachments.service';
import { setFamilyName, setModel, setModels, } from '../../redux/slices/attachments';
import { routes } from '../../routes';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import './ModelSelection.scss';
export var ModelSelection = function () {
    var modelGroup = useParams().modelGroup;
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var _a = useAppSelector(function (state) { return state.attachments; }), models = _a.models, familyName = _a.familyName;
    var _b = useState([]), currentModels = _b[0], setCurrentModels = _b[1];
    var data = useQuery({
        enabled: models.length === 0 && !!modelGroup,
        queryKey: [modelGroup],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, attachmentsService.getFamilyModels(modelGroup)];
            });
        }); },
    }).data;
    var modelChunks = splitToChunks(currentModels, 3);
    var chooseModel = function (currentModel) {
        navigate(routes.categories(familyName, currentModel));
        dispatch(setModel(currentModel));
        sessionStorage.setItem('currentModel', currentModel);
    };
    var filterModels = function (value) {
        var modelsCopy = models.filter(function (item) {
            return item.toLocaleLowerCase().includes(value.toLocaleLowerCase());
        });
        setCurrentModels(modelsCopy);
    };
    var modelItems = modelChunks.map(function (container, i) { return (_jsx("div", { children: container.map(function (item) { return (_jsx("button", { onClick: function () { return chooseModel(item); }, className: "Model-Item", children: item }, item)); }) }, i)); });
    useEffect(function () {
        if (data) {
            dispatch(setModels(data.data));
        }
    }, [data]);
    useEffect(function () {
        if (models) {
            setCurrentModels(models);
        }
    }, [models]);
    useEffect(function () {
        if (!familyName) {
            dispatch(setFamilyName(modelGroup));
        }
    }, [familyName, dispatch]);
    return (_jsx(DefaultLayout, { children: _jsxs("div", { className: "Model-Selection", children: [_jsx(Breadcrumb, { breadcrumbs: [
                        {
                            label: 'machine selection',
                            to: '/',
                        },
                        {
                            label: familyName,
                        },
                    ], children: _jsx(TextField, { className: "Model-Input", placeholder: "Type model name", onChange: function (e) {
                            filterModels(e.target.value);
                        }, InputProps: {
                            startAdornment: (_jsx(InputAdornment, { position: "start", children: _jsx(FontAwesomeIcon, { icon: faFilter }) })),
                        } }) }), _jsxs("div", { className: "Model-Selection-Wrapper", children: [_jsx("h4", { className: "Model-Title", children: "Select a model below" }), _jsx("div", { className: "Models", children: modelItems })] })] }) }));
};

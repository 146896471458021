import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { MenuItem } from '../MenuItem';
import './MenuList.scss';
export var MenuList = function (props) {
    var menuItems = props.menuItems, _a = props.onClick, onClick = _a === void 0 ? undefined : _a, _b = props.className, className = _b === void 0 ? '' : _b;
    var menuElements = menuItems === null || menuItems === void 0 ? void 0 : menuItems.map(function (item) { return (_jsx(MenuItem, { href: item.href, label: item.label, onClick: onClick }, item.href)); });
    var classes = classNames('Menu-List', className);
    return _jsx("ul", { className: classes, children: menuElements });
};

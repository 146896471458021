import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import './sp.scss';
import 'core-js/es/symbol';
import App from './App';
var queryClient = new QueryClient();
// eslint-disable-next-line
ReactDOM.render(_jsx(BrowserRouter, { children: _jsx(Provider, { store: store, children: _jsx(QueryClientProvider, { client: queryClient, children: _jsx(App, {}) }) }) }), document.getElementById('root'));

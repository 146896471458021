var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import './FilterGroup.scss';
export var FilterGroup = function (props) {
    var title = props.title, filters = props.filters, name = props.name, facetData = props.facetData;
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    var param = searchParams.get(name);
    var _b = useState(false), isVisible = _b[0], setIsVisible = _b[1];
    var isOptionsExist = filters.length > 0;
    var addFilter = function (value) {
        var _a;
        var prevValue = searchParams.get(name);
        var parsedValue = prevValue ? JSON.parse(prevValue) : [];
        if (typeof value === 'string') {
            if (parsedValue.includes(value)) {
                parsedValue = parsedValue.filter(function (item) { return item !== value; });
            }
            else {
                parsedValue.push(value);
            }
        }
        else {
            if (parsedValue.some(function (item) { return item.min === value.min; })) {
                parsedValue = parsedValue.filter(function (item) { return item.min !== value.min; });
            }
            else {
                parsedValue.push(value);
            }
        }
        if (parsedValue.length === 0) {
            searchParams.delete(name);
            setSearchParams(searchParams);
        }
        else {
            var mergedParams = __assign(__assign({}, Object.fromEntries(searchParams)), (_a = {}, _a[name] = JSON.stringify(parsedValue), _a));
            setSearchParams(mergedParams);
        }
    };
    var filterItems = useMemo(function () {
        return filters.map(function (item) {
            var _a;
            var facet = facetData === null || facetData === void 0 ? void 0 : facetData.filters.find(function (facet) { return JSON.stringify(facet.value) === JSON.stringify(item.value); });
            var classes = classNames('checkbox-label', {
                disabled: !facet,
            });
            return (_jsxs("div", { className: "Checkbox-Flex", children: [_jsx("input", { id: "".concat(name, "-").concat(item.label), className: "checkbox", name: item.label, type: "checkbox", disabled: !facet, checked: !!(param === null || param === void 0 ? void 0 : param.includes(JSON.stringify(item.value))), onChange: function () { return addFilter(item.value); } }), _jsx("label", { className: classes, htmlFor: "".concat(name, "-").concat(item.label), dangerouslySetInnerHTML: { __html: (_a = facet === null || facet === void 0 ? void 0 : facet.label) !== null && _a !== void 0 ? _a : item.label } })] }, item.label));
        });
    }, [filters, param, facetData]);
    var classes = classNames('Accordion-Button', {
        disabled: !isOptionsExist,
    });
    useEffect(function () {
        if (param) {
            setIsVisible(true);
        }
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "Accordion-Top", children: _jsxs("button", { className: classes, disabled: !isOptionsExist, onClick: function () { return setIsVisible(!isVisible); }, children: [_jsx("span", { "data-testid": "accordion-icon", children: _jsx(FontAwesomeIcon, { icon: isVisible && isOptionsExist ? faCaretDown : faCaretRight, size: "1x" }) }), title] }) }), isVisible && isOptionsExist ? (_jsx("div", { className: "Accordion-Content", "data-testid": "accordion-content", children: filterItems })) : undefined] }));
};

import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import { HtmlView } from '../../widgets/HtmlView';
/**
 * Component that provides additional styling for legal documents.
 *
 * @param props - Component props.
 */
export var LegalDocumentView = function (_a) {
    var className = _a.className, children = _a.children;
    var classes = classNames('LegalDocumentView', className);
    return _jsx(HtmlView, { className: classes, children: children });
};

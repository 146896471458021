var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useAnalyticsEventTracker } from '../../../../hooks/useAnalyticsEventTracker';
import './SearchInput.scss';
export var SearchInput = function () {
    var eventTracker = useAnalyticsEventTracker('Attachments Search').eventTracker;
    var _a = useState(''), descriptionValue = _a[0], setDescriptionValue = _a[1];
    var _b = useSearchParams(), searchParams = _b[0], setSearchParams = _b[1];
    var handleSearch = function () {
        var description = descriptionValue.trim();
        var mergedParams = __assign(__assign({}, Object.fromEntries(searchParams)), { description: description });
        eventTracker("Keyword \"".concat(description, "\" was searched"));
        setSearchParams(mergedParams);
        setDescriptionValue('');
    };
    var handleKeyEvent = function (event) {
        if (!descriptionValue)
            return;
        if (event === 'Enter') {
            handleSearch();
        }
    };
    return (_jsxs("div", { className: "Search", children: [_jsx("button", { className: "Search-Button", "data-testid": "Search-Button", onClick: handleSearch, disabled: !descriptionValue, children: _jsx(FontAwesomeIcon, { icon: faSearch, className: "Search-Icon" }) }), _jsx("input", { "data-testid": "Search-Input", className: "Search-Input", placeholder: "Search attachments", value: descriptionValue, onChange: function (e) { return setDescriptionValue(e.target.value); }, onKeyUp: function (e) {
                    handleKeyEvent(e.code);
                } })] }));
};
